<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="full-width-image section1">
                <div class="hero-content">
                    <!-- <span class="hero-icon"><font-awesome-icon icon="coffee"/></span> -->
                    <span class="hero-text">Latest News from the OffshoreSMS team.</span>
                </div>
            </div>

            <div class="container full-width">
                <div class="hero has-text-centered mb-5">
                    <div class="spacer s20"></div>
                    <!-- <p class="title is-3 is-info">Digital Logs</p> -->
                    <p class="subtitle is-5">A collection of our latest news, email newsletter updates and new features.</p>
                </div>

                <!-- <div class="buttons centered">
                    <span v-if="!userExists" class="button is-medium is-success" @click="trackLink('sign-up', 'signup')">Join for FREE and decide later</span>
                    <span v-else class="button is-medium is-primary" @click="trackLink('my-dashboard', 'dashboard')">Member Log in</span>
                </div> -->
                <news-menu currentTab="start"></news-menu>
            </div>
        </div>

        <div class="buttons floating">
            <span @click="goBack()" class="button is-info"><font-awesome-icon icon="chevron-left" /> &nbsp; Back</span>
            <button class="button is-medium is-primary" @click="openEnquiry = true"><font-awesome-icon icon="comments" /> &nbsp; Quick Enquiry</button>
        </div>

        <quick-enquiry v-if="openEnquiry" @closed="close()" />
    </div>
</template>

<script>
import { db } from '@/main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import QuickEnquiry from '../reusables/QuickEnquiry.vue';
const NewsMenu = () => import('@/news/NewsMenu.vue');

export default {
    components: {
        QuickEnquiry,
        NewsMenu
    },
    data: function() {
        return {
            openEnquiry: false
        };
    },
    computed: {
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        }
    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },

        close() {
            this.openEnquiry = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.full-width-image {
    height: 160px;
    width: 100%;
    position: relative;
    &.section1 {
        background: url('../assets/img/offshore-sms-boat-tied-2.jpg') no-repeat center center;
        background-size: cover;
        .hero-content {
            position: absolute;
            color: white;
            display: flex;
            align-items: center;
            top: 35%;
            left: 10%;
            font-size: 1.8rem;
            align-items: flex-start;
            margin-right: 2rem;
            .hero-icon {
                // padding-top: 3px;
                margin-right: 10px;
            }
            .hero-text {
                line-height: 2rem;
            }
        }
    }
}
.site-wrapper {
    padding-top: 0;
}
</style>
